<template>
  <el-steps direction="vertical" :active="active" v-if="jumpStep">
    <el-step>
      <template v-slot:icon>
        <span>预</span>
      </template>
    </el-step>
    <el-step>
      <template v-slot:icon>
        <span>学1</span>
      </template>
    </el-step>
    <el-step>
      <template v-slot:icon>
        <span>学2</span>
      </template>
    </el-step>
    <el-step>
      <template v-slot:icon>
        <span>学3</span>
      </template>
    </el-step>
    <el-step>
      <template v-slot:icon>
        <span>练1</span>
      </template>
    </el-step>
    <el-step>
      <template v-slot:icon>
        <span>练2</span>
      </template>
    </el-step>
    <el-step>
      <template v-slot:icon>
        <span>测</span>
      </template>
    </el-step>
  </el-steps>
  <div v-else class="el-steps el-steps--vertical jumpStep">
    <div class="el-step__head" :class="(!jumpStep && index !== 4 && index != 5) ? 'is-finish' : ''" v-for="item,index in list" :key="index">
      <div class="el-step__line"></div>
      <div class="el-step__icon is-text">
        <span>{{item}}</span>
      </div>
    </div>
  </div>
  <p class="returnbtn" @click="back" v-if="(routerName !== 'wordlearning') && (active !== 7)"></p>
</template>
<script>
import { reactive, toRefs } from "vue-demi";
import { getCurrentInstance } from "vue";
export default {
  props: {
    active: {
      type: Number,
      default: 1,
    },
    jumpStep: {
      type: Boolean,
      default: true
    },
  },
  setup(props) {
    const { appContext } = getCurrentInstance();
    const that = appContext.config.globalProperties;
    const data = reactive({
      routerName: that.$route.name,
      list: ['预', '学1', '学2', '学3', '练1', '练2', '测']
    });
    const back = () => {
      if (that.$route.name === 'textlearning') {
        that.$router.replace({ name: 'wordlearning', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: that.$route.query.kwid } })
      } else if (props.active === 2) {
        that.$router.replace({ name: 'textlearning', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: that.$route.query.kwid } })
      } else if (props.active === 3) {
        that.$router.replace({ name: 'studyFirstStep', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: that.$route.query.kwid } })
      } else if (props.active === 4) {
        that.$router.replace({ name: 'studyTwoStep', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: that.$route.query.kwid } })
      } else if (props.active === 5) {
        that.$router.replace({ name: 'studyThreeStep', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: that.$route.query.kwid } })
      } else if (props.active === 6) {
        that.$router.replace({ name: 'practiceOneStep', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: that.$route.query.kwid } })
      }
    }
    return {
      ...toRefs(data),
      back,
    };
  },
};
</script>
<style lang="scss" scoped>
.returnbtn {
  width: 4.06vw;
  height: 4.06vw;
  background: url("../../../assets/student/wordreturn.png") no-repeat;
  background-size: 100% 100%;
  position: fixed;
  left: 4vw;
  bottom: 2.8vw;
  z-index: 2;
  cursor: pointer;
}
.jumpStep {
  height: 107%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .el-step__head {
    flex: 1;
  }
  .el-step__line {
    height: 98%;
  }
  .el-step__icon {
    border-radius: 50%;
    border: 2px solid #fbb7b7;
  }
  .el-step__head:last-child{
    .el-step__line {
      height: 0;
    }
  }
}
/deep/.el-step__icon {
  width: 3vw;
  height: 3vw;
  background: #fd706c;
  color: #fff;
  font-size: 1.5vw;
  border: 2px solid #fbb7b7;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
}
/deep/.el-step__line {
  left: 1.35vw !important;
  width: 0.5vw !important;
  background: #ffcdcc;
  position: absolute;
  top: 2px !important;
}
/deep/.is-finish {
  .el-step__line {
    background: #0972e7;
  }
  .el-step__icon {
    background: #0972e7;
    border: 2px solid #58a3f6;
  }
}
</style>